
.flex-div {
  height: 100vh;
  padding-bottom: 100px;
  text-align: center;
  img {
    padding-bottom: 30px;
  }
  .title {
    padding-bottom: 20px;
  }
}
